const data = [
    {
        name: "Jane Doe",
        email: "jane@me.com",
        password: "Access123"
    },
    {
        name: "Peter Parker",
        email: "peter@mit.edu",
        password: "Passcode321"
    },
    {
        name: "John Smith",
        email: "john@msn.com",
        password: "Letmein33"
    }
];

export default data;